<template>
  <div class="container template-page">
    <div class="content">
      <div id="view_warper" ref="pdfDom" class="b-l p-l-20 view_warper template">
        <div v-if="archivesInfo.archivesType == '2'">
          <h1 class="t-a-c">轻微火灾登记书</h1>
          <table border="1" cellspacing="0" cellpadding="0" class="table_content">
            <tr class="fire-time">
              <th colspan="4">起火时间</th>
              <td colspan="16">
                <div class="td-wrap">
                  <span style="width: 100%; text-align: left;cursor: text;"
                    :style="{ color: formData.fireTime ? '' : '#c0c4cc' }" @click="getFocus">
                    {{ formData.fireTime || '请选择' }}
                  </span>
                  <el-date-picker ref="datePickerRef" class="td-hide" v-model="formData.fireTime" type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" align="right" :picker-options="pickerOptions">
                  </el-date-picker>
                </div>
              </td>
            </tr>
            <tr>
              <th colspan="4">起火地址</th>
              <td colspan="16">
                <div class="td-wrap">
                  <span style="width: 100%; text-align: left;"
                    :style="{ color: formatAddress(formData.fireAddress) ? '' : '#c0c4cc' }">{{
                      formatAddress(formData.fireAddress) || '请选择' }}</span>
                  <avue-input-map class="td-hide" :params="{ zoom: 10 }" placeholder="请选择"
                    v-model="formData.fireAddress" />
                </div>
                <!-- <el-input v-else type="text" v-model="formData.fireAddressText" placeholder="请输入" /> -->
              </td>
            </tr>
            <tr>
              <th colspan="4">起火单位</th>
              <td colspan="16">
                <el-input v-model="formData.fireCompany" placeholder="请输入..." />
              </td>
            </tr>
            <tr>
              <th colspan="4">联系人</th>
              <td colspan="6">
                <el-input v-model="formData.contactor" placeholder="请输入..." />
              </td>
              <th colspan="4">联系电话</th>
              <td colspan="6">
                <el-input type="number" v-model="formData.contactorPhone" placeholder="请输入..." />
              </td>
            </tr>
            <tr>
              <th colspan="4">场所类型</th>
              <td colspan="6">
                <el-input v-model="formData.siteType" placeholder="请输入..." />
              </td>
              <th colspan="4">起火物</th>
              <td colspan="6">
                <el-input v-model="formData.fireItem" placeholder="请输入..." />
              </td>
            </tr>
            <tr>
              <th colspan="4">过火面积</th>
              <td colspan="6">
                <el-input v-model="formData.burnedArea" placeholder="请输入..." />
              </td>
              <th colspan="4">直接经济损失</th>
              <td colspan="6">
                <el-input v-model="formData.economicLoss" placeholder="请输入..." />
              </td>
            </tr>
            <tr>
              <th colspan="4">保险情况</th>
              <td colspan="16">
                <el-input v-model="formData.insurance" placeholder="请输入..." />
              </td>
            </tr>
            <tr style="height: 210px">
              <th colspan="4">当事人签名</th>
              <td colspan="16">
                <div class="preparer">
                  <p>本人对火灾事故事实无异议，不需要消防救援机构出具火灾事故认定结论。</p>
                  <div flex="cross:center" style="text-indent: 4em;">
                    <span>当事人签名：</span>
                    <div flex="cross:center" style="padding:5px;">
                      <!-- <p><img v-if="formData.preparer" :src="formData.preparer" alt="" /></p> -->
                      <img v-if="formData.preparer" :src="formData.preparer" alt="" />
                      <el-link class="margin_left_10" type="primary"
                        @click="(signatureVisible = true), (currentKey = 'preparer')">签名
                      </el-link>
                    </div>
                  </div>
                  <p>时间：{{ parseTime(formData.signTime) }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <th colspan="4">统计人签名</th>
              <td colspan="6">
                <div flex="cross:center main:justify" style="padding:5px;">
                  <p><img v-if="formData.statisticstor" :src="formData.statisticstor" alt="" /></p>
                  <el-link class="margin_left_10" type="primary"
                    @click="(signatureVisible = true), (currentKey = 'statisticstor')">签名
                  </el-link>
                </div>
              </td>
              <th colspan="4">统计时间</th>
              <td colspan="6">
                <div class="td-wrap">
                  <span style="width: 100%; text-align: left;"
                    :style="{ color: formData.statisticsTime ? '' : '#c0c4cc' }">{{ formData.statisticsTime ||
                      '请选择' }}</span>
                  <el-date-picker class="td-hide" v-model="formData.statisticsTime" type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" align="right" :picker-options="pickerOptions">
                  </el-date-picker>
                </div>
              </td>
            </tr>
            <tr>
              <th colspan="4">备注</th>
              <td colspan="16">
                <el-input v-model="formData.remark" placeholder="请输入..." />
              </td>
            </tr>
          </table>
        </div>
        <div v-else>
          <h1 class="t-a-c">火灾事故简易调查认定书</h1>
          <table border="1" cellspacing="0" cellpadding="0" class="table_content">
            <tr>
              <th colspan="4">起火单位（个人）</th>
              <td colspan="7">
                <el-input v-model="formData.fireCompany" placeholder="请输入..." />
              </td>
              <th colspan="4">法定代表人/主要负责人</th>
              <td colspan="5">
                <el-input v-model="formData.representative" placeholder="请输入..." />
              </td>
            </tr>
            <tr>
              <th colspan="4">起火地址</th>
              <td colspan="7">
                <div class="td-wrap">
                  <span style="width: 100%; text-align: left;"
                    :style="{ color: formatAddress(formData.fireAddress) ? '' : '#c0c4cc' }">{{
                      formatAddress(formData.fireAddress) || '请选择' }}</span>
                  <avue-input-map class="td-hide" :params="{ zoom: 10 }" placeholder="请选择"
                    v-model="formmatAddressValue" />
                </div>
                <!-- <el-input v-else type="text" v-model="formData.fireAddressText" placeholder="请输入" /> -->
              </td>
              <th colspan="4">联系电话</th>
              <td colspan="5">
                <el-input type="number" v-model="formData.contactorPhone" placeholder="请输入..." />
              </td>
            </tr>
            <tr>
              <th colspan="4">报警时间</th>
              <td colspan="7">
                <div class="td-wrap">
                  <!-- <span>{{formData.callTime}}</span> -->
                  <!-- <el-input v-model="formData.callTime" placeholder="请选择"></el-input> -->
                  <span style="width: 100%; text-align: left;" :style="{ color: formData.callTime ? '' : '#c0c4cc' }">{{
                    formData.callTime || '请选择' }}</span>
                  <el-date-picker class="td-hide" v-model="formData.callTime" type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" align="right" :picker-options="pickerOptions">
                  </el-date-picker>
                </div>
              </td>
              <th colspan="4">直接财产损失统计(元)</th>
              <td colspan="5">
                <el-input type="number" v-model="formData.economicLoss" placeholder="请输入..." />
              </td>
            </tr>
            <tr style="height: 180px">
              <th colspan="4">现场调查走访情况</th>
              <td colspan="16">
                <el-input type="textarea" :autosize="{ minRows: 7, maxRows: 7 }" placeholder="请输入..."
                  v-model="formData.visits" />
              </td>
            </tr>
            <tr style="height: 180px">
              <th colspan="4">火灾事故事实</th>
              <td colspan="16">
                <el-input type="textarea" :autosize="{ minRows: 7, maxRows: 7 }" placeholder="请输入..."
                  v-model="formData.fireAccident" />
              </td>
            </tr>
            <tr>
              <th colspan="4">告知</th>
              <td colspan="16" style="padding:5px">根据《火灾事故调查规定》第三十六条第一款第四项的规定，适用简易调查程序作出的火灾事故认定，消防救援机构不予受理复核申请。</td>
            </tr>
            <tr style="height: 120px">
              <th colspan="4">当事人签名</th>
              <td colspan="16">
                <div class="preparer">
                  <p>本人对该起火灾事故认定没有异议</p>
                  <div flex="cross:center" style="text-indent: 4em;">
                    <span>当事人签名：</span>

                    <div flex="cross:center" style="padding:5px;">
                      <!-- <p><img v-if="formData.preparer" :src="formData.preparer" alt="" /></p> -->
                      <img v-if="formData.preparer" :src="formData.preparer" alt="" />
                      <el-link class="margin_left_10" type="primary"
                        @click="(signatureVisible = true), (currentKey = 'preparer')">签名
                      </el-link>
                    </div>
                  </div>
                  <p>时间：{{ formData.signTime }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <th colspan="4">调查人员签名</th>
              <td colspan="7">
                <div flex="cross:center main:justify" style="padding:5px;">
                  <p><img v-if="formData.investigators" :src="formData.investigators" alt="" /></p>
                  <el-link class="margin_left_10" type="primary"
                    @click="(signatureVisible = true), (currentKey = 'investigators')">签名
                  </el-link>
                </div>
              </td>
              <th colspan="4">消防机构印章及认定日期</th>
              <td colspan="5">
                <div class="td-wrap">
                  <span style="width: 100%; text-align: left;"
                    :style="{ color: formData.firmlyBelieve ? '' : '#c0c4cc' }">{{ formData.firmlyBelieve || '请选择'
                    }}</span>
                  <el-date-picker class="td-hide" v-model="formData.firmlyBelieve" type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" align="right" :picker-options="pickerOptions">
                  </el-date-picker>
                </div>
              </td>
            </tr>
            <tr>
              <th colspan="4">备注</th>
              <td colspan="16">
                <el-input v-model="formData.remark" placeholder="请输入..." />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <FooterBtn class="footer" :pdfDownload="pdfDownload" :submitDis="false" @submit="submitForm" @cancel="handleCancel" />
    <DownloadBtn class="download-btn" :pdfDownload="pdfDownload"
      :title="archivesInfo.archivesType == '2' ? '轻微火灾登记书' : '火灾事故简易调查认定书'" :keyId="params.keyId" :formData="formData"
      :moduleName="currentModule.moduleName" @saveData="submitFormBackBefore" />
    <SignatureDialog v-model="signatureVisible" @submit="generate" />
  </div>
</template>

<script>

import DataFlow from '@/mixins/DataFlow'
import { get as GetArchivesInfo } from '@/api/archives'
import { pickerOptions } from '@/const/avue'
import FooterBtn from '@/components/FooterBtn'
import SignatureDialog from '@/components/SignatureDialog'
import DownloadBtn from '@/components/DownloadBtn'
export default {
  components: { SignatureDialog, FooterBtn, DownloadBtn },
  mixins: [DataFlow],
  data() {
    return {
      archivesInfo: {},
      pickerOptions,
      signatureVisible: false,
    }
  },
  computed: {
    hostName() {
      return location.hostname
    },
    formatAddress() {
      return address => {
        if (!address || !address.length) return "";
        if (address instanceof Array) {
          return address[2];
        } else {
          return address.split(",")[2];
        }
      };
    },
    formmatAddressValue: {
      get() {
        return this.formData?.fireAddress ? this.formData?.fireAddress.split(',') : []
      },
      set(val) {
        this.formData.fireAddress = val.join(',')
      }
    }
  },
  watch: {
    '$route.query': {
      async handler(newVal) {
        let archivesInfoRes = await GetArchivesInfo(newVal.archivesId)
        this.archivesInfo = archivesInfoRes.data
      },
      immediate: true
    },
  },
  methods: {
    getFocus() {
      this.$refs.datePickerRef.focus()
    },
    generate(base64) {
      const arr = this.currentKey.split(".");
      arr.reduce((prev, curr, index) => {
        if (index === arr.length - 1) {
          prev[curr] = base64
        }
        return prev[curr];
      }, this.formData);

      // 只有在非 简易火灾 才会有 下面字段
      if (this.archivesInfo.archivesType != 2) {
        this.formData.investigatorsTime = this.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}');
      }
      if (this.currentKey == "preparer") {
        this.formData.signTime = this.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}');
      }
    },
    initForm() {
      const { fireAddress, fireName, callTime } = this.archivesData
      let initForm = {
        callTime: callTime || this.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
        fireTime: this.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
        fireAddress: fireAddress || '',
        fireCompany: fireName,
        contactor: "",
        contactorPhone: "",
        siteType: "",
        fireItem: "",
        burnedArea: "",
        economicLoss: "",
        insurance: "",
        preparer: "",
        statisticstor: "",
        statisticsTime: this.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
        firmlyBelieve: '',
        remark: "",
        signTime: "",
        investigatorsTime: ""
      }
      return initForm
    }
  }
}
</script>
<style scoped lang="scss">
.container {
  table {
    .preparer {
      text-align: left;

      &>p:first-of-type {
        text-indent: 2em;
      }

      &>p:nth-of-type(2) {
        text-indent: 4em;
      }

      &>p:nth-of-type(3) {
        text-indent: 7em;
      }
    }

    img {
      width: 80px;
      // border: 1px solid #606266;
    }
  }
}

.view_warper {
  font-size: 17px;
  color: #000;
  padding: 15px 30px 70px;
  position: relative;
  height: 100%;
  overflow-y: auto;

  p,
  pre {
    margin: 0;
    line-height: 36px;

    // font-family: FZST;
    span {
      text-decoration: underline;
      display: inline-block;
      // border-bottom: 1px solid #000;
    }
  }

  pre {
    text-decoration: underline;
    // max-width: 50%;
    white-space: pre-wrap;
    word-wrap: break-word;
    // border-bottom: 2px solid #000;
  }

  ::v-deep .table_content {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    vertical-align: center;
    box-sizing: border-box;
    color: #606266;
    font-size: 13px;
    table-layout: fixed;
    height: 800px;
    font-size: 16px;

    tr.fire-time {
      .td-wrap {
        .el-date-editor {
          width: 200px;
        }
      }
    }

    th {
      padding: 6px 18px;
      font-size: 16px;
      font-weight: 400;
    }

    td {
      position: relative;

      &>input,
      &>textarea,
      &>.box {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        text-align: left;
        padding-left: 10px;
        box-sizing: border-box;
        border: none;
        resize: none;
        font-family: inherit;
        background-color: #ebf0ff;
      }

      .el-input__inner,
      .el-textarea__inner {
        border: none;
        // background-color: #f0f0f0;
      }

      input[type="date"],
      input[type="time"] {
        background-color: #ebf0ff;
      }

      .td-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 16px;

        // .el-date-editor--datetime {}



        .td-hide {
          position: absolute;
          left: 0;
          opacity: 0;
          width: 100%;
        }
      }

      .el-input,
      .el-textarea {

        .el-input__inner,
        .el-textarea__inner {
          height: 100%;
          font-size: 16px;
        }
      }


    }
  }
}
</style>